import React, { useState, useEffect } from 'react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

function Map() {
  const [mapDimensions, setMapDimensions] = useState({ width: '50rem', height: '20rem' });
  const [markers, setMarkers] = useState([{ lat: 49.10323964625827, lng: 17.408414438713525 },
    { lat: 49.09739157225404, lng: 17.419818327894824 }]);


  useEffect(() => {
    function handleResize() {
      setMapDimensions({
        width: `${Math.min(window.innerWidth, 50 * 16)}px`, // Zde 16 je 1rem v pixelech
        height: `${Math.min(window.innerHeight, 20 * 16)}px`
      });
    }

    handleResize(); // Zavoláme handleResize při načtení stránky, aby mapa měla správnou velikost

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const center = {
    lat: 49.10123964625827,
    lng: 17.418414438713525
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
      <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLEAPIKEY}>
        <GoogleMap
          mapContainerStyle={mapDimensions}
          center={center}
          zoom={15}
        >
          {markers.map((marker, index) => (
            <Marker key={index} position={{ lat: marker.lat, lng: marker.lng }} />
          ))}
        </GoogleMap>
      </LoadScript>
    </div>
  );
}

export default Map;
