import React from 'react';
import './App.scss'
import './home.scss'
import { Container } from 'react-bootstrap';
import HeaderPart from './HeaderPart'
import HeaderMenu from './HeaderMenu'
import './popUp.scss'


const Schedule = () => {


    return (
        <div className='App'>
            <HeaderPart/>
            <Container>
            <HeaderMenu/>
            <h1>Harmonogram</h1>
            <br/>
            <div className='sg-panel sg-panel--green--panel'>
                <ul>
                    <li><strong>11:00</strong> – Příchod hostů</li>
                    <li><strong>12:00</strong> – Obřad</li>
                    <li><strong>12:30</strong> – Gratulace, focení s hosty</li>
                    <li><strong>13:30</strong> – Hostina</li>
                    <li><strong>15:30</strong> – První tanec, Tanec s rodiči</li>
                    <li><strong>16:00</strong> – Krájení dorty</li>
                    <li><strong>16:30</strong> – Hry, tombola</li>
                    <li><strong>18:30</strong> – Rozplétání kytice, podvazek</li>
                    <li><strong>19:00</strong> – Grilování</li>
                    <li><strong>20:00</strong> – Rok s novomanželi</li>
                    <li><strong>21:00</strong> – Prskavkový tanec</li>
                    <li><strong>21:30</strong> – Hry, volná zábava</li>
                </ul>
            </div>
            </Container>
        </div>
    );
};

export default Schedule