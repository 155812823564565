import React, { Component } from 'react';
import './Multicombobox.scss';
import { Form } from 'react-bootstrap';
import './App.scss';

class MultiComboBox extends Component {
  constructor(props) {
    super(props);
    const defaultSelected = props.defaultSelected || [];
    this.state = {
      isOpen: this.props.allergiesChecked,
      selectedOptions: defaultSelected,
      allergiesChecked: this.props.allergiesChecked,
      prevDefaultSelected: defaultSelected,
    };
  }

  toggleDropdown = () => {
    this.setState((prevState) => ({ isOpen: !prevState.isOpen }));
  };

  handleOptionClick = (option) => {
    const { selectedOptions } = this.state;
    if (selectedOptions.includes(option)) {
      this.setState({
        selectedOptions: selectedOptions.filter((item) => item !== option),
      });
    } else {
      this.setState({ selectedOptions: [...selectedOptions, option] });
    }
  };


  handleAllergiesChange = () => {
    this.setState((prevState) => ({
      allergiesChecked: !prevState.allergiesChecked,
      isOpen: !prevState.allergiesChecked,
      selectedOptions: prevState.allergiesChecked ? [] : prevState.selectedOptions,
    }));
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.defaultSelected !== this.props.defaultSelected) {
      const { defaultSelected } = this.props;
      this.setState({
        selectedOptions: defaultSelected,
        prevDefaultSelected: defaultSelected,
      });
    }
    if (prevProps.allergiesChecked !== this.props.allergiesChecked) {
      this.setState({ 
        allergiesChecked: this.props.allergiesChecked,
        isOpen: this.props.allergiesChecked,
      })
    }
  }

  render() {
    const { options } = this.props;
    const { isOpen, selectedOptions, allergiesChecked } = this.state;

    return (
      <div className="form_enter">
        <div className={`multicombobox-toggle ${isOpen ? 'open' : ''}`} onClick={this.toggleDropdown}>
          <Form.Check
            id="allergies"
            type="checkbox"
            label="Zadat alergie"
            checked={allergiesChecked}
            onChange={this.handleAllergiesChange}
          />
        <br></br>
        </div>
        {isOpen && (
          <ul className="form-check form-switch multicombobox-switch">
            {options.map((option) => (
              <li key={option}>
                <input
                  type="checkbox"
                  className="form-check-input"
                  id={option}
                  checked={selectedOptions.includes(option)}
                  onChange={() => this.handleOptionClick(option)}
                />
                {option}
              </li>
            ))}
          </ul>
        )}
      </div>
    );
  }
}

export default MultiComboBox;
