import React, { useState, useEffect } from 'react';
import {useNavigate} from 'react-router-dom'
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import './App.scss'
import './home.scss'
import './popUp.scss'
import Axios from 'axios'
import PopUp from './PopUp'
import ComboBox from './ComboBox'
import ComboBoxSimple from './ComboBoxSimple'
import queryString from 'query-string'

import HeaderPart from './HeaderPart'
import HeaderMenu from './HeaderMenu'
import { getCookieValue } from './getCookieValue.js'
import Map from './Map';


function App() {

const apiUri = process.env.REACT_APP_APIURL+'instancedata/'
const apiUriMeal = process.env.REACT_APP_APIURL+'meallist/'
const navigate = useNavigate();

const isLoggedIn = document.cookie.includes("loggedInWedding=true");

const queryParams = queryString.parse(window.location.search)

const [members, setMembers] = useState(0);

const [weddingId, setWeddingId] = useState('')

const [userId, setUserId] = useState('')

const [configSave, setConfigSave] = useState(false)

const [config, setConfig] = useState({})

const [accomodation, setAccomodation] = useState(false)

const [childrensInAccomodation, setChildrensInAccomodation] = useState(0)

const [grownInAccomodation, setGrownInAccomodation] = useState(0)

const [defaultCheckedValues, setDefaultCheckedValues] = useState([])

const [allergiesSet, setAllergiesSet] = useState(false)

const [selectedOptions, setSelectedOptions] = useState({});

const [mealList, setMealList] = useState([])

const [note, setNote] = useState('')

const [email, setEmail] = useState('')
const [loading, setloading] = useState(false)


const options = ['Lepek', 'Korýši', 'Vejce', 'Ryby', 'Arašídy', 'Sója', 'Mléko', 'Skořápkové plody',
'Celer', 'Hořčice', 'Sezam', 'Oxid siřičitý a siřičitany', 'Vlčí bob', 'Měkkýši']

const base64Credentials = btoa(process.env.REACT_APP_APIUSERNAME+':'+process.env.REACT_APP_APIPASSWORD);
const authHeader = 'Basic ' + base64Credentials;

const userConfig = {
  headers: {
    Authorization: authHeader
  }
};


useEffect(() => {

  if(!document.cookie.includes("weddingId") || document.cookie.includes("weddingId=undefined")){
    document.cookie = `weddingId=${queryParams?.weddingId}`
    setWeddingId(queryParams?.weddingId)
  }
  else setWeddingId(getCookieValue("weddingId"))
  if(!document.cookie.includes("userId") || document.cookie.includes("userId=undefined")){
    document.cookie = `userId=${queryParams?.userId}`
    setUserId(queryParams?.userId)
  }
  else setUserId(getCookieValue("userId"))

  console.log("cookie", document.cookie)

}, []);

useEffect(() => {
  if (config?.allergies !== undefined) {
    setAllergiesSet(config.allergies);
  }
}, [config?.allergies]);


useEffect(() => {
  let guests =[]

  if(config !== null || config !== undefined)
  {
    setMembers(config?.numberOfMembers)
    setAccomodation(config?.accomodation)
    setChildrensInAccomodation(config?.numberOfChildrenAccommodation)
    setGrownInAccomodation(config?.numberOfGrownAccommodation)
    setEmail(config?.email)
    setAllergiesSet(config?.allergies)
    setSelectedOptions(filteredOptions)
  
  
    setDefaultCheckedValues(findCheckedAllergies(config, options))
  }

}, [config]);


useEffect(() => {
  const timer = setTimeout(() => {
    setConfigSave(false);
  }, 5000);

  return () => clearTimeout(timer);
}, [configSave]);


useEffect(() => {

  const loadConf = () => {
    Axios.get(apiUri+weddingId+'/'+userId, userConfig)
      .then(function (response) {
          
        setConfig(response?.data.findedConfig)
        console.log("data z API",response?.data.findedConfig)

      })
      .catch(function (error) {
        console.log(error);
      })
  }

  const loadMealList = () => {
    Axios.get(apiUriMeal+weddingId, userConfig)
      .then(function (response) {
          
        setMealList(response?.data.meals[0]?.mealVariants)
        console.log("data z mealListu",response?.data.meals[0]?.mealVariants)

      })
      .catch(function (error) {
        console.log(error);
      })
  }

  if(weddingId){

        const run = async () =>{ 
          try{
            loadConf()
            loadMealList()
          }catch (error) {
            console.log(error)
            throw error
          }
        }
      

        run()

    }

}, [weddingId, userId]);

const findCheckedAllergies = (obj, arr) => {
  const filtrovanePole = [];
  // Projít všechny položky v poli
  arr.forEach(polozka => {
    // Zkontrolovat, zda obj je objekt a má vlastnost polozka, která není null nebo undefined, a zda má hodnotu true
    if (obj && obj.hasOwnProperty(polozka) && obj[polozka] === true) { 
      filtrovanePole.push(polozka); // Přidat položku do filtrovaného pole
    }
  });

  console.log("filtrovanoe",filtrovanePole)
  return filtrovanePole; // Vrátit filtrované pole
}






const handleAccomodationChange = (event) => {

  setAccomodation(event.target.checked)
}

const handleNumberGrownChange = (event) => {

  setGrownInAccomodation(event.target.value)
}

const handleNumberChildrenChange = (event) => {

  setChildrensInAccomodation(event.target.value)
}

const handleNoteChange = (event) => {

  setNote(event.target.value)
}

const handleEmailChange = (event) => {

  setEmail(event.target.value)
}

const handleSubmit = async (event) => {
  event.preventDefault();

  // Získání formuláře
  const form = event.target;

  // Získání hodnoty počtu dospělých osob
  const numberOfGrownAccommodation = parseInt(document.getElementById('numberOfGrown').value);

  // Získání hodnoty počtu dětí
  const numberOfChildrenAccommodation = parseInt(document.getElementById('numberOfChildren').value);

  const numberOfMembers = parseInt(document.getElementById('numberOfMembers').value);

  const email = document.getElementById('email').value;

  // Získání prvků formuláře
  const elements = Array.from(form);

  let result = [];

  result.push({
    value: weddingId,
    id: 'weddingId'
  });
  result.push({
    value: userId,
    id: 'userId'
  });
  result.push({
    value: numberOfMembers,
    id: 'numberOfMembers'
  });
  result.push({
    value: numberOfGrownAccommodation,
    id: 'numberOfGrownAccommodation'
  });
  result.push({
    value: numberOfChildrenAccommodation,
    id: 'numberOfChildrenAccommodation'
  });
  result.push({
    value: email,
    id: 'email'
  });
  result.push({
    value: note,
    id: 'note'
  });

  // Převedení prvků formuláře na pole a použití map
  elements.forEach((element) => {

    // Zde můžete pracovat s každým prvkem formuláře
    if (element.type === 'checkbox') {
      result.push({
        value: element.checked,
        id: element.id
      });
    } else if (element.type === 'select-one') {
      result.push({
        value: element.value,
        id: element.id
      });
    }
  });

  console.log("result", result);

  // Změna struktury objektu result na požadovaný formát
  const resultObject = {};
  result.forEach((item) => {
    resultObject[item.id] = item.value;
  });

  console.log("resultObject", resultObject);

  const saveToDb = async () => {
    setloading(true)
    try {
      console.log("objekt na API", resultObject);
      const response = await Axios.post(apiUri, resultObject, userConfig);
      response.status === 201 || response.status === 200 ? setConfigSave(true) : setConfigSave(false);
      console.log("response status",response.status)
      setloading(false)
    } catch (error) {
      console.log(error);
      setloading(false)
      throw error; // Re-throw the error to be caught in handleSave if needed
    }
  };

  try {
    await saveToDb(); // Počkejte, dokud saveToDb() neskončí
  } catch (error) {
    console.log(error);
    throw error; // Re-throw the error to be caught in handleSave if needed
  }
};
const filteredOptions =  Object.entries(config || {})
  .filter(([key, value]) => parseInt(key) >= 100 && parseInt(key) < 300)
  .reduce((acc, [key, value]) => {
    acc[key] = value;
    return acc;
  }, {})

const handleNumberOfMembersChange = (event) => {

    setMembers(parseInt(event.target.value, 10) || 0);

  }

  const handleOptionChange = (number, value) => {
    setSelectedOptions(prevOptions => ({
      ...prevOptions,
      [number]: value
    }));
    console.log("selectedOptions", selectedOptions)
  };

  
  const listItems = Array.from({ length: members }, (_, index) => (
    <div key={index + 1}>
    <Row key={index + 1} style={{ marginBottom: '10px' }}>
      <Col md={6} xs={12}>
      <ComboBoxSimple
        options={['Dospělá osoba', 'Dítě']}
        number={100 + index + 1}
        selectedOption={selectedOptions[100 + index + 1] || ''}
        onChange={(value) => handleOptionChange(100 + index + 1, value)}
      />
      </Col>
      <Col md={6} xs={12}>
      <ComboBoxSimple
        options={mealList}
        number={200 + index + 1}
        selectedOption={selectedOptions[200 + index + 1] || ''}
        onChange={(value) => handleOptionChange(200 + index + 1, value)}
      />
      </Col>
    </Row>
    {index < members - 1 && <hr className="combobox-divider" />}
    </div>
  ));

if(isLoggedIn){
  return (
    <div className="App">
      <HeaderPart/>
      <Container>
      <HeaderMenu/>
        <Row className="head_part">
          <Col>
              <div className="col">
                <div className="row">
                  <div className="col-12">
                    <div className="sg-panel sg-panel--head-info">
                      <p className="head_text">
                        Ahoj, podařilo se přihlásit? Super! Tato aplikace slouží pro získání informací od vás a my vám sem zase za odměnu
                        budeme přidávat průběžné informace a další překvapení.
                      </p>
                      <p className="lower_text">
                        Aby jste si s námi mohli společně tento den maximálně užít, vyplňte nám prosím údaje níže. Mockrát děkujeme!
                      </p>
                      <p className="head_text">
                        <b>Moc se na tu jízdu těšíme!!</b>
                      </p>
                      </div>
                    </div>
                  </div>
                </div>
          </Col>
        </Row>
          <h1>Mapa svatby a ubytování</h1>
          <Map />
        <Row>
          <Col>
              <div className="col">
                <div className="row">
                  <div className="col-12">
                    <div className="sg-panel sg-panel--paper">
                        <h1>Potvrďte svou účast</h1>
                        <br></br>
                        <Form onSubmit = {handleSubmit}>
                          <Form.Group>
                            <Form.Label>Zadejte e-mail pro notifikace:</Form.Label>
                            <br></br>
                            <Form.Control style={{fontSize: '20pt'}} type="text" id='email' value={email} className="text-area" onChange={handleEmailChange} />
                          </Form.Group>
                          <Form.Group>
                            <Form.Label>Počet účastníků svatby za vaši rodinu:</Form.Label>
                            <br></br>
                            <Form.Control style={{fontSize: '20pt'}} type="number" id='numberOfMembers' value={members} className="text-area" onChange={handleNumberOfMembersChange} />
                          </Form.Group>
                          <Form.Group className="food">
                            <Form.Label><b>Výběr jídla</b></Form.Label>
                              <ul>
                              {config !== null || config !==undefined ? listItems : ''}
                              </ul>
                          </Form.Group>
                          <Form.Group>
                          <br></br>
                            <Form.Label><b>Alergie a intolerance</b></Form.Label>
                            <br></br>
                            <br></br>
                              <ComboBox options={options} defaultSelected={defaultCheckedValues} allergiesChecked={allergiesSet} savedState={config}/>
                          </Form.Group>
                          <Form.Group>
                            <br></br>
                            <Form.Label className="form_enter"><b>Ubytování</b></Form.Label>        
                            <br></br>
                            <Form.Check id='accomodation' className="form_enter" type="checkbox" label="Chci ubytování" onChange={handleAccomodationChange} checked={accomodation} />
                            <br></br>
                            <Form.Label style={{ display: accomodation ? '' : 'none'}}>Počet dospělých osob k ubytování</Form.Label>
                            <br></br>
                            <Form.Control style={{ display: accomodation ? '' : 'none', fontSize: '20pt'}} id='numberOfGrown' className="text-area" onChange={handleNumberGrownChange} type="number" value={grownInAccomodation}/>
                            <br></br>
                            <Form.Label style={{ display: accomodation ? '' : 'none'}} >Počet dětí k ubytování</Form.Label>
                            <br></br>
                            <Form.Control style={{ display: accomodation ? '' : 'none', fontSize: '20pt'}} id='numberOfChildren' className="text-area" onChange={handleNumberChildrenChange} type="number" value={childrensInAccomodation} />
                          </Form.Group>
                          <br></br>
                          <Form.Group>
                            <Form.Label>Prostor pro libovolnou další poznámku</Form.Label>
                            <br></br>
                            <Form.Control style={{fontSize: '20pt'}} as="textarea" id='note' className="textarea" onChange={handleNoteChange} />
                          </Form.Group>
                          {loading ? <div className="spinner-border text-warning" role="status"></div> : null}
                          <br></br>
                          <br></br>
                          {configSave && <PopUp message="Data byla úspěšně uložena!" />}
                          <Button className="button" variant="btn btn-success" type="submit">
                            Potvrdit účast
                          </Button>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
else return (navigate('/login'))
}


export default App;