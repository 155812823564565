import React, {useState, useEffect} from 'react';
import './App.scss'
import './home.scss'
import { Container } from 'react-bootstrap';
import HeaderPart from './HeaderPart'
import HeaderMenu from './HeaderMenu'
import Axios from 'axios'
import { getCookieValue } from './getCookieValue.js'


const QuizAnswers = () => {

    const apiUri = process.env.REACT_APP_APIURL+'users/'
    const apiUriGet = process.env.REACT_APP_APIURL+'getquizanswers/'

    const [users, setUsers] = useState([])
    const [answers, setAnswers] = useState([])

    const base64Credentials = btoa(process.env.REACT_APP_APIUSERNAME+':'+process.env.REACT_APP_APIPASSWORD);
    const authHeader = 'Basic ' + base64Credentials;

    const userConfig = {
    headers: {
        Authorization: authHeader
    }
    };

      
    useEffect(() => {

        Axios.get(apiUri+getCookieValue('weddingId'), userConfig)
            .then(function (response) {
            console.log("response?.data?.userNames", response?.data?.userNames)
            setUsers(response?.data?.userNames)

            })
            .catch(function (error) {
                console.log(error);
            })

        Axios.get(apiUriGet+getCookieValue('weddingId'), userConfig)
            .then(function (response) {
                
                console.log("response?.data?.answers", response?.data?.answers)
                setAnswers(response?.data?.answers)

            })
            .catch(function (error) {
                console.log(error);
            })

    }, []);


    return (
        <div className='App'>
            <HeaderPart/>
            <Container>
            <HeaderMenu/>
            <h1>Odpovědi</h1>
            <br/>
            {answers.map(answer => 

                <div className="sg-panel sg-panel--paper">
                    <b>Soutěžící:</b> {users.filter(user => user._id === answer.userId)[0]?.username}
                    <br/>
                    <b>Počet správných odpovědí:</b> {answer.score}
                    <br/>
                    <br/>
                    <b><i>Jednotlivé odpovědi</i></b>
                    <br/>
                    <br/>
                    <ul>
                        {answer.responses.map(point => 
                            <li>
                                <b>Otázka: {point.question}</b>
                                <br/>
                                Odpověď: {point.selectedOption}
                            </li>
                        )}
                    </ul>
                </div>
            )}
            
            </Container>
        </div>
    );
};

export default QuizAnswers