import React, {useState} from 'react';
import Editor from './Editor';
import './App.scss'
import './home.scss'
import { Container } from 'react-bootstrap';
import HeaderPart from './HeaderPart'
import HeaderMenu from './HeaderMenu'
import './popUp.scss'
import PopUp from './PopUp'

const BlogPage = () => {

    const [successSave, setSuccessSave] = useState(false)
    const saveArticle = (content) => {

        setSuccessSave(true)
        console.log('Uložení článku do databáze:', content);
    };

    return (
        <div className='App'>
            <HeaderPart/>
            <Container>
            <HeaderMenu/>
            <h1>Editor článků</h1>
            <Editor onSave={saveArticle} />
            <br/>
            {successSave && <PopUp message="Data byla úspěšně uložena!" />}
            </Container>
        </div>
    );
};

export default BlogPage