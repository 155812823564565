import React, { useState, useEffect, useMemo, useRef } from 'react';
import Axios from 'axios';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './App.scss';
import './home.scss';
import './editor.scss';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { getCookieValue } from './getCookieValue.js';
import Pica from 'pica';

// Definice možností toolbaru
const ToolbarOptions = [
  ['bold', 'italic', 'underline', 'strike'],
  ['blockquote', 'code-block'],
  [{ 'header': 1 }, { 'header': 2 }],
  [{ 'list': 'ordered' }, { 'list': 'bullet' }],
  [{ 'script': 'sub' }, { 'script': 'super' }],
  [{ 'indent': '-1' }, { 'indent': '+1' }],
  [{ 'direction': 'rtl' }],
  [{ 'size': ['small', false, 'large', 'huge'] }],
  [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
  [{ 'color': [] }, { 'background': [] }],
  [{ 'font': [] }],
  [{ 'align': [] }],
  ['link', 'image'],
  ['clean']
];

const Editor = ({ onSave }) => {
  const apiUri = process.env.REACT_APP_APIURL + 'savearticle/';
  const [content, setContent] = useState('');
  const [weddingId, setWeddingId] = useState('');
  const quillRef = useRef(null);

  const base64Credentials = btoa(process.env.REACT_APP_APIUSERNAME + ':' + process.env.REACT_APP_APIPASSWORD);
  const authHeader = 'Basic ' + base64Credentials;

  const userConfig = {
    headers: {
      Authorization: authHeader
    }
  };

  const handleImageUpload = () => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();

    input.onchange = async () => {
      const file = input.files[0];
      const reader = new FileReader();
      reader.onload = async () => {
        const img = new Image();
        img.src = reader.result;
        img.onload = async () => {
          const pica = Pica();
          const canvas = document.createElement('canvas');
          const maxWidth = 300;
          const maxHeight = 200;
          let width = img.width;
          let height = img.height;

          if (width > height) {
            if (width > maxWidth) {
              height = Math.round((height *= maxWidth / width));
              width = maxWidth;
            }
          } else {
            if (height > maxHeight) {
              width = Math.round((width *= maxHeight / height));
              height = maxHeight;
            }
          }

          canvas.width = width;
          canvas.height = height;

          await pica.resize(img, canvas);

          const base64 = canvas.toDataURL('image/png');
          const quill = quillRef.current.getEditor();
          const range = quill.getSelection();
          quill.insertEmbed(range.index, 'image', base64);
        };
      };
      reader.readAsDataURL(file);
    };
  };

  const modules = useMemo(() => ({
    toolbar: {
      container: ToolbarOptions,
      handlers: {
        image: handleImageUpload,
      }
    }
  }), []);

  const handleSave = () => {
    onSave(content);
    const result = {
      weddingId: weddingId,
      content: content
    };

    Axios.post(apiUri + weddingId, result, userConfig)
      .then(function (response) {
        console.log('Článek uložen', response);
      })
      .catch(function (error) {
        console.log(error);
      });

    setContent('');
  };

  useEffect(() => {
    if (document.cookie.includes('weddingId')) {
      setWeddingId(getCookieValue('weddingId'));
    }
  }, []);

  return (
    <div className='App'>
      <Container>
        <Row>
          <Col>
            <div className="col">
              <div className="row">
                <div className="col-12">
                  <div className="sg-panel sg-panel--paper">
                    <ReactQuill
                      ref={quillRef}
                      value={content}
                      onChange={setContent}
                      theme="snow"
                      modules={modules}
                      placeholder="Zadejte zde obsah článku..."
                    />
                    <Button className="button" variant="btn btn-success" type="button" onClick={handleSave}>
                      Uložit článek
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Editor;