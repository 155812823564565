import React, {useState, useEffect} from 'react'
import Axios from 'axios'
import './App.scss'
import './home.scss'
import { Container, Form, Row, Col, Button } from 'react-bootstrap'
import { getCookieValue } from './getCookieValue.js'

import HeaderPart from './HeaderPart'
import HeaderMenu from './HeaderMenu'
import './popUp.scss'
import PopUp from './PopUp'

function MealList() {

    const apiUri = process.env.REACT_APP_APIURL+'savemeals/'
    const apiUriGet = process.env.REACT_APP_APIURL+'meallist/'
    const [mealVariantNumber, setMealVariantNumber] = useState(0)
    const [mealVariants, setMealVariants] = useState([])
    const [weddingId, setWeddingId] = useState('')
    const [successSave, setSuccessSave] = useState(false)

    const base64Credentials = btoa(process.env.REACT_APP_APIUSERNAME+':'+process.env.REACT_APP_APIPASSWORD);
    const authHeader = 'Basic ' + base64Credentials;
  
    const userConfig = {
      headers: {
        Authorization: authHeader
      }
    };

    useEffect(() => {
        if(document.cookie.includes("weddingId")){
            setWeddingId(getCookieValue("weddingId"))
            findInDb()
        }
    }, []);

    useEffect(() => {
        if(weddingId !== '') {
            findInDb();
        }      
    }, [weddingId]);

    useEffect(() => {
        setMealVariants(Array.from({ length: mealVariantNumber }, (_, index) => mealVariants[index] || ''));
    }, [mealVariantNumber]);

    const handleSubmit = (event) => {
        event.preventDefault();

        const result ={
            weddingId: weddingId,
            mealVariantNumber: mealVariantNumber,
            mealVariants: mealVariants    
        }

        Axios.post(apiUri+weddingId, result, userConfig)
            .then(function (response) {    
                console.log("Varianty jídel uloženy!", response)
                setSuccessSave(true)
            })
            .catch(function (error) {
                console.log(error);
            })
    }

    const handleMealVariantNumberChange = (event) => {
        const count = parseInt(event.target.value, 10) || 0;
        setMealVariantNumber(count);
    }

    const handleMealVariantChange = (index, value) => {
        const updatedVariants = [...mealVariants];
        updatedVariants[index] = value;
        setMealVariants(updatedVariants);
    }

    const findInDb = () => {
        Axios.get(apiUriGet+weddingId, userConfig)
          .then(function (response) {
            const data = response.data.meals[0];
            setMealVariantNumber(data?.mealVariantNumber || 0);
            setMealVariants(Array.from({ length: data?.mealVariantNumber || 0 }, (_, index) => data?.mealVariants[index] || ''));
            console.log("data z meal listu:", data);
          })
          .catch(function (error) {
            console.log(error);
          })
    }

    const listItems = mealVariants.map((variant, index) => (
        <li key={index + 1}>
            <Form.Group>
                <Form.Label>Hodnota varianty jídla {index+1}</Form.Label>
                <br></br>
                <Form.Control style={{fontSize: '20pt'}} type="text" value={variant || ''} className="text-area" onChange={(e) => handleMealVariantChange(index, e.target.value)} />
            </Form.Group>
        </li>
    ));

    return (
        <div className='App'>
            <HeaderPart/>
            <Container>
                <HeaderMenu/>
                <Row>
                    <Col>
                        <div className="col">
                            <div className="row">
                                <div className="col-12">
                                    <div className="sg-panel sg-panel--paper">
                                        <Form onSubmit = {handleSubmit}>
                                            <ul>
                                                <li>
                                                <Form.Group>
                                                    <Form.Label><b>Počet variant jídel</b></Form.Label>
                                                    <br></br>
                                                    <Form.Control style={{fontSize: '20pt'}} type="number" id='numberOfMembers' value={mealVariantNumber} className="text-area" onChange={handleMealVariantNumberChange} />
                                                </Form.Group>
                                                </li>
                                            </ul>
                                            <ul>
                                                {listItems}
                                            </ul>
                                            <Button className="button" variant="btn btn-success" type="submit">
                                                Uložit
                                            </Button>
                                        </Form>
                                        {successSave && <PopUp message="Data byla úspěšně uložena!" />}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default MealList
