import React from 'react';
import {useNavigate} from 'react-router-dom'
import { Container, Row, Col, Button } from 'react-bootstrap';
import './App.scss'
import './home.scss'
import myIcon from './logout_icon.png'
import CountDown from './CountDown'

const HeaderPart= () => {
    
    const navigate = useNavigate();

    return (
        <div className="App">
          <Container>
            <Row>
                <Col>
                    <div className="col">
                      <div className="row">
                        <div className="col-4 offset-8">
                          <div className="sg-panel--logout" >
                            <Button className="button_logout_icon" variant="btn" type="button" onClick={() => { document.cookie = `loggedInWedding=false;`; document.cookie = `userId=undefined;`; document.cookie = "isAdmin=false"; navigate('/login') }}>
                              LogOut
                              <img src={myIcon} alt="My Icon" height={"45"}/>
                           </Button>
                          </div>
                        </div>
                       </div>
                    </div>
                </Col>
              </Row>
          </Container>
          <header className="App-header">
            <h1>Vítejte v naší svatební aplikaci!</h1>
            <p className="white"><b> 20. července 2024</b></p>
             <a href="https://www.velehradskevinice.cz/" className="link-light link-underline link-underline-opacity-0" target="_blank" rel="noreferrer">Velehradské vinice</a>
            <CountDown/>
          </header>
          </div>
    )

}

export default HeaderPart