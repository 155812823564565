import React, { useState, useEffect } from 'react';
import './popUp.scss'

const Popup = ({ message }) => {
  const [showPopup, setShowPopup] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowPopup(false);
    }, 10000); // 10 sekund

    return () => clearTimeout(timer);
  }, []);

  return (
    <div>
      {showPopup && (
        <div className='popUp'>
          <p>{message}</p>
        </div>
      )}
    </div>
  );
};

export default Popup;
