import React, {useState, useEffect} from 'react';
import './App.scss';
import './home.scss';
import { Container, Form, Button} from 'react-bootstrap';
import MultiComboBox from './ComboBoxQuiz'
import { getCookieValue } from './getCookieValue.js'
import Axios from 'axios'

import HeaderPart from './HeaderPart';
import HeaderMenu from './HeaderMenu';


function Quiz() {
    const apiUri = process.env.REACT_APP_APIURL + 'savequiz';
    const apiUriGet = process.env.REACT_APP_APIURL + 'getquizanswers/';
  
    const base64Credentials = btoa(process.env.REACT_APP_APIUSERNAME + ':' + process.env.REACT_APP_APIPASSWORD);
    const authHeader = 'Basic ' + base64Credentials;
  
    const userConfig = {
      headers: {
        Authorization: authHeader,
      },
    };
  
    const questions = [
      {
        question: "Kde jsme se dali dohromady?",
        options: ["Trenčín", "Brno", "Tatry"],
        correctAnswer: "Tatry",
        popUpAnswer: "Prvý krát sme sa uvideli v Trenčíne, celý príbeh sa ale začal písať v Tatrách ⛰",
      },
      {
        question: "Jak dlouho už jsme spolu?",
        options: ["6", "7", "5"],
        correctAnswer: "6",
        popUpAnswer: "Už je to 6 rokov plných krásnych ale aj ťažkých chvíľ, ktoré sme spolu napokon zvládli. ♥",
      },
      {
        question: "Kolikrát jsme se už společně stěhovali?",
        options: ["4x", "3x", "2x"],
        correctAnswer: "3x",
        popUpAnswer: "V sťahovaní sme už majstri 😂 Spolu sme sa sťahovali 3x, inak za život nevesta 8x a ženích 11x.",
      },
      {
        question: "Jaký je mezi námi věkový rozdíl?",
        options: ["6", "4", "5"],
        correctAnswer: "5",
        popUpAnswer: "Takmer na deň presne je to 5 ročný rozdiel 😊",
      },
      {
        question: "Jak velkou má ženich nohu?(Sranda, tipni si 😁)",
        options: ["50", "48", "49"],
        correctAnswer: "49",
        popUpAnswer: "Ženích má naozaj veľkosť nohy 49 😨 Výber topánok bol teda náročnejší pre ženícha. 🚢",
      },
      {
        question: "Kde proběhla žádost o ruku?",
        options: ["Doma", "V Tatrách", "V restauraci na večeři"],
        correctAnswer: "Doma",
        popUpAnswer: "Tak, ako to máme radi – doma v súkromí. 😊",
      },
      {
        question: "Kolik sourozenců máme dohromady?",
        options: ["5", "7", "8"],
        correctAnswer: "8",
        popUpAnswer: "Svadba bude veľká či chceme alebo nie 😅 Nevesta má 5 bratov, ženích 2 sestry a 1 brata.",
      },
      {
        question: "Jak se jmenuje náš pes?",
        options: ["Jacky", "Johny", "Marley"],
        correctAnswer: "Jacky",
        popUpAnswer: "Oficiálne sa volá Jack, prezývok má ale nespočetne 🐶",
      },
      {
        question: "Půjde náš Matýsek už do školy?",
        options: ["Ne, školkáček", "Do školky, ale předškolák", "Jasné, prvňáček"],
        correctAnswer: "Do školky, ale předškolák",
        popUpAnswer: "Čas letí, z nášho Matýska už je predškolák. 👨‍👩‍👦",
      },
      {
        question: "Jaká je naše profese?",
        options: ["Projekťáci", "Programátoři", "Obchodníci"],
        correctAnswer: "Obchodníci",
        popUpAnswer: "Vyjednávacie schopnosti obchoďákov po nás preberá už aj Matýsek, máme sa na čo tešiť! 😂",
      },
    ];
  
    const [answers, setAnswers] = useState({});
    const [submitted, setSubmitted] = useState(false);
    const [score, setScore] = useState(0);
  
    useEffect(() => {
      const fetchData = async () => {
        const weddingId = getCookieValue("weddingId");
        const userId = getCookieValue("userId");
  
        try {
          const response = await Axios.get(`${apiUriGet}${weddingId}/${userId}`, userConfig);
          if (response.status === 200 && response.data.data.length > 0) {
            const savedAnswers = response.data.data[0].responses.reduce((acc, curr, index) => {
              acc[index] = curr.selectedOption;
              return acc;
            }, {});
            const savedScore = response.data.data[0].score;
  
            setAnswers(savedAnswers);
            setScore(savedScore);
            setSubmitted(true);
          }
        } catch (error) {
          console.error('Error fetching quiz answers:', error);
        }
      };
  
      fetchData();
    }, []);
  
    const handleOptionSelected = (index, option) => {
      setAnswers((prevAnswers) => ({
        ...prevAnswers,
        [index]: option,
      }));
    };
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      let newScore = 0;
  
      questions.forEach((question, index) => {
        if (answers[index] === question.correctAnswer) {
          newScore += 1;
        }
      });
  
      const payload = {
        weddingId: getCookieValue("weddingId"),
        userId: getCookieValue("userId"),
        responses: questions.map((question, index) => ({
          question: question.question,
          selectedOption: answers[index] || '',
        })),
        score: newScore,
        submited: true,
      };
  
      try {
        const response = await Axios.post(apiUri, payload, userConfig);
        console.log("data z kvízu", response?.data.data);
        setScore(newScore);
        setSubmitted(true);
      } catch (error) {
        console.error('Error submitting quiz:', error);
      }
    };
  
    const getScoreFeedback = (score) => {
      if (score >= 7) {
        return "Zaslúžiš si potlesk! 👏Tvoja miestenka na svadbu je naozaj zaslúžená 😉";
      } else if (score >= 4) {
        return "Zlatý stred, skoč s novomanželmi na vínko a trochu ich vyspovedaj 😉";
      } else {
        return "Dúfame, že máš len zlý deň a naozaj vieš toho o nás viac 🤣 Nevadí, skočíme spolu na vínko a napravíme to! 😉";
      }
    };
  
    return (
      <div className="App">
        <HeaderPart />
        <Container>
          <HeaderMenu />
          {submitted && (
            <div className="score-section sg-panel sg-panel--green--panel">
              <h3><b>Celkový počet bodů: {score} / {questions.length}</b></h3>
              <p><b>{getScoreFeedback(score)}</b></p>
            </div>
          )}
          <Form onSubmit={handleSubmit}>
            {questions.map((question, index) => (
              <div key={index} className="sg-panel sg-panel--paper">
                <Form.Group>
                  <Form.Label><b>{question.question}</b></Form.Label>
                  <br />
                  <MultiComboBox
                    options={question.options}
                    name={`question-${index}`}
                    savedState={answers[index]}
                    readOnly={submitted}
                    onOptionSelected={(option) => handleOptionSelected(index, option)}
                  />
                  {submitted && (
                    <div>
                      <p style={{ color: answers[index] === question.correctAnswer ? 'green' : 'red' }}>
                        <b>Vaše odpověď:</b> <b>{answers[index]}</b>
                      </p>
                      <p style={{color:'green'}}><b>Správná odpověď:</b>  <b>{question.correctAnswer}</b></p>
                      <p><b>{question.popUpAnswer}</b></p>
                    </div>
                  )}
                  <br />
                </Form.Group>
              </div>
            ))}
            {!submitted && (
              <Button className="button" variant="btn btn-success" type="submit">
                Uložit
              </Button>
            )}
          </Form>
        </Container>
      </div>
    );
  }
  
  export default Quiz;