import React, { Component } from 'react';
import './Multicombobox.scss';
import './App.scss';
import { Form } from 'react-bootstrap';

class MultiComboBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOption: '',
    };
  }

  handleOptionClick = (option) => {
    this.setState({ selectedOption: option });
    if (this.props.onOptionSelected) {
      this.props.onOptionSelected(option);
    }
  };

  componentDidMount() {
    if (this.props.savedState) {
      this.setState({ selectedOption: this.props.savedState });
    }
  }

  render() {
    const { options, name, readOnly } = this.props;
    const { selectedOption } = this.state;

    return (
      <div className="form_enter">
        {options.map((option) => (
          <Form.Check
            key={option}
            id={`${name}-${option}`}
            className="form_enter"
            type="radio"
            label={option}
            name={name}
            checked={selectedOption === option}
            onChange={() => this.handleOptionClick(option)}
            disabled={readOnly}
          />
        ))}
      </div>
    );
  }
}

export default MultiComboBox;