import React, { useState, useEffect } from 'react';
import './App.scss';
import './home.scss';
import { useNavigate } from 'react-router-dom';
import { Container, Form, Row, Button} from 'react-bootstrap';

import HeaderPart from './HeaderPart';
import HeaderMenu from './HeaderMenu';


function CreateQuestions() {
  const navigate = useNavigate();
  const isLoggedIn = document.cookie.includes("loggedInWedding=true");

  const [question, setQuestion] = useState('')
  const [numberOfQuestions, setNumberOfQuestions] = useState(0)
  const [correctAnswer, setCorrectAnswer] = useState('')


  useEffect(() => {
    
  }, []);

  const handleSubmit = () => {

  }

  const handleQuestionChange= (event) => {

    setQuestion(event.target.value)
  }
  
  const handleNumberOfQuestions = (event) => {
  
    setNumberOfQuestions(event.target.value)
  }
  
  const handlecorrectAnswer = (event) => {
  
    setCorrectAnswer(event.target.value)
  }

  const listItems = Array.from({ length: numberOfQuestions }, (_, index) => (
    <div key={index + 1}>
    <Row key={index + 1} style={{ marginBottom: '10px' }}>
    <Form.Group>
        <Form.Label>Odpověď {+index + 1}:</Form.Label>
            <br></br>
            <Form.Control style={{fontSize: '20pt'}} type="text" id='question' value={question} className="text-area" onChange={handleQuestionChange} />
        </Form.Group>
    </Row>
    </div>
  ));


  if (!isLoggedIn) {
    navigate('/login');
    return null;
  }

  return (
    <div className='App'>
      <HeaderPart />
      <Container>
        <HeaderMenu/>
        <Form onSubmit = {handleSubmit}>
                          <Form.Group>
                            <Form.Label>Dotaz:</Form.Label>
                            <br></br>
                            <Form.Control style={{fontSize: '20pt'}} type="text" id='question' value={question} className="text-area" onChange={handleQuestionChange} />
                          </Form.Group>
                          <Form.Group>
                            <Form.Label>Počet možných odpovědí:</Form.Label>
                            <br></br>
                            <Form.Control style={{fontSize: '20pt'}} type="number" id='numberOfQuestions' value={numberOfQuestions} className="text-area" onChange={handleNumberOfQuestions} />
                          </Form.Group>
                            {listItems}
                          <Form.Group>
                            <Form.Label>Správná odpověď:</Form.Label>
                            <br></br>
                            <Form.Control style={{fontSize: '20pt'}} type="text" id='correctAnswer' value={correctAnswer} className="text-area" onChange={handlecorrectAnswer} />
                          </Form.Group>
                          <Button className="button" variant="btn btn-success" type="submit">
                            Uložit
                          </Button>
        </Form>
      </Container>
    </div>
  );
}

export default CreateQuestions;
