import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './App.scss'
import './home.scss'
import Axios from 'axios'
import HeaderPart from './HeaderPart'
import HeaderMenu from './HeaderMenu'
import { getCookieValue } from './getCookieValue.js'

const Output = () => {

    const apiUri = process.env.REACT_APP_APIURL + 'config/' + getCookieValue("weddingId")
    const apiUriUser = process.env.REACT_APP_APIURL + 'user/'

    const [loadData, setLoadData] = useState([])
    const [countGuests, setCountGuests] = useState(0)
    const [countAccomodation, setCountAccomodation] = useState(0)
    const [countAccomodationAdult, setCountAccomodationAdult] = useState(0)
    const [countAccomodationChildren, setCountAccomodationChildren] = useState(0)
    const [numberOfChildren, setNumberOfChildren] = useState(0)
    const [userIds, setUserIds] = useState({});
    const [isLoaded, setIsLoaded] = useState(false);

    const base64Credentials = btoa(process.env.REACT_APP_APIUSERNAME + ':' + process.env.REACT_APP_APIPASSWORD);
    const authHeader = 'Basic ' + base64Credentials;

    const userConfig = {
        headers: {
            Authorization: authHeader
        }
    };

    const alergies = ['Lepek', 'Korýši', 'Vejce', 'Ryby', 'Arašídy', 'Sója', 'Mléko', 'Skořápkové plody',
        'Celer', 'Hořčice', 'Sezam', 'Oxid siřičitý a siřičitany', 'Vlčí bob', 'Měkkýši'];

    useEffect(() => {
        Axios.get(apiUri, userConfig)
            .then(function (response) {

                console.log("Nalezené objekty", response.data.confData)
                setNumberOfChildren(najitDeti(response.data.confData))
                setLoadData(response.data.confData)

            })
            .catch(function (error) {
                console.log(error);
            })

    }, []);

    useEffect(() => {
        if (loadData.length > 0) {
            let userIdsTemp = {};
            loadData.forEach(config => {
                setCountGuests(prevCountGuests => prevCountGuests + (config.numberOfMembers > 0 ? config.numberOfMembers : 0));
                setCountAccomodationAdult(prevCountAccomodationAdult => prevCountAccomodationAdult + (config.numberOfGrownAccommodation > 0 ? config.numberOfGrownAccommodation : 0));
                setCountAccomodationChildren(prevCountAccomodationChildren => prevCountAccomodationChildren + (config.numberOfChildrenAccommodation > 0 ? config.numberOfChildrenAccommodation : 0));

                if (!userIdsTemp[config.userId]) {
                    userIdsTemp[config.userId] = '';
                }
            });
            setUserIds(userIdsTemp);
        }
    }, [loadData]);

const loadUser = async (id) => {
      try {
          const response = await Axios.get(apiUriUser + id, userConfig);
          console.log("Uživatel", response.data.userName.username);
          return response.data.userName.username;
      } catch (error) {
          console.log("Chyba při načítání uživatele:", error);
          return ''; // Return empty string in case of error
      }
  }
  
  useEffect(() => {
    const fetchUserNames = async () => {
        let updatedUserIds = {};
        setIsLoaded(true)
        for (const userId of Object.keys(userIds)) {
            const userName = await loadUser(userId);
            updatedUserIds[userId] = userName;
        }
        setUserIds(updatedUserIds);
    };

    // Fetch user names only if userIds is not empty
    if (Object.keys(userIds).length > 0 && !isLoaded) {
        fetchUserNames();
    }
}, [userIds]);

const najitDeti = (objekt) => {
  let detiVeVsechObjektech = [];

  // Projít každý objekt v poli
    objekt.forEach(objekt => {
    // Projít všechny vlastnosti objektu
    for (let key in objekt) {
      // Pokud je hodnota vlastnosti rovna "Dítě", přidejte klíč do pole detiVeVsechObjektech
      if (objekt[key] === "Dítě") {
        detiVeVsechObjektech.push(key);
      }
    }
  });

  // Vrátit pole detiVeVsechObjektech
  return detiVeVsechObjektech.length;
}

const findChildrenInObject = (objekt) => {
  // Inicializace pole pro ukládání výsledků
  let deti = [];

  // Projít všechny vlastnosti objektu
  for (let key in objekt) {
    // Pokud je hodnota vlastnosti rovna "Dítě", přidejte klíč do pole deti
    if (objekt[key] === "Dítě") {
      deti.push(key);
    }
  }

  // Vrátit pole deti
  return deti.length;
}



    return (
        <div className='App'>
            <HeaderPart />
            <Container>
                <HeaderMenu />
                <Row>
                    <Col>
                        <div className="col">
                            <div className="row">
                                <div className="col-12">
                                    <div className="sg-panel-nav sg-panel--paper list">
                                        <ul>
                                            <li>
                                                Celkový počet hostů: {countGuests}
                                            </li>
                                            <li>
                                                Celkový počet dětí: {numberOfChildren}
                                            </li>
                                            <li>
                                                Celkový počet dospělých - ubytování: {countAccomodationAdult}
                                            </li>
                                            <li>
                                                Celkový počet dětí - ubytování: {countAccomodationChildren}
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
                {loadData.length > 0 ? loadData.map((config) => (
                    <Row key={config._id}>
                        <Col>
                            <div className="col">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="sg-panel-nav sg-panel--paper list">
                                            <ul>
                                                <li>
                                                    Host: <b>{userIds[config.userId]}</b>
                                                </li>
                                                <li>
                                                    Počet hostů: {config.numberOfMembers}
                                                </li>
                                                <li>
                                                    Počet dětí: {findChildrenInObject(config)}
                                                </li>
                                                <li>
                                                    E-mail: {config.email}
                                                </li>
                                                <li>
                                                    Host1: {config["101"]}
                                                </li>
                                                <div className="li_move">
                                                    <li>
                                                        {config["201"]}
                                                    </li>
                                                </div>
                                                <li>
                                                    Host2: {config["102"]}
                                                </li>
                                                <div className="li_move">
                                                    <li>
                                                        {config["202"]}
                                                    </li>
                                                </div>
                                                <li>
                                                    Host 3: {config["103"]}
                                                </li>
                                                <div className="li_move">
                                                    <li>
                                                        {config["203"]}
                                                    </li>
                                                </div>
                                                <li>
                                                    Ubytování: {config.accomodation ? "Ano" : "Ne"}
                                                </li>
                                                <li>
                                                    Poznámka: {config.note}
                                                </li>
                                                <li>
                                                    Alergie: {config.allergies ? "Ano" : "Ne"}
                                                </li>
                                                {alergies.filter(alergieItem => config[alergieItem] === true).length > 0 ? alergies.filter(alergieItem => config[alergieItem] === true).map(finded => (
                                                    <div className="li_move" key={finded}>
                                                        <li>
                                                            {finded}
                                                        </li>
                                                    </div>
                                                )) : null}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                )) : ''}
            </Container>
        </div>
    );
};

export default Output;
