import React from 'react';
import './App.scss';
import './home.scss';
import { Container } from 'react-bootstrap';
import HeaderPart from './HeaderPart';
import HeaderMenu from './HeaderMenu';
import placesImage from './places.png'; // Import obrázku

const Places = () => {
    return (
        <div className='App'>
            <HeaderPart />
            <Container>
                <HeaderMenu />
                <h1>Zasedací pořádek</h1>
                <br/>
                <img src={placesImage} alt="Zasedací pořádek" style={{ width: '100%', height: 'auto' }} /> {/* Přidání obrázku */}
            </Container>
        </div>
    );
};

export default Places;
