import React, { useState, useEffect } from 'react';
import moment from 'moment';

const CountdownTimer = () => {
  const [remainingTime, setRemainingTime] = useState('');

  useEffect(() => {
    const interval = setInterval(() => {
      const now = moment();
      const futureDate = moment('2024-07-20 12:00:00');
      const duration = moment.duration(futureDate.diff(now));

      const days = Math.floor(duration.asDays());
      const hours = duration.hours();
      const minutes = duration.minutes();
      const seconds = duration.seconds();

      setRemainingTime(`${days} days, ${hours} hours, ${minutes} minutes, ${seconds} seconds`);

      // Zastavíme interval, když zbývající čas dosáhne 0
      if (duration.asMilliseconds() <= 0) {
        clearInterval(interval);
        setRemainingTime('Expired');
      }
    }, 1000);

    return () => clearInterval(interval); // Uklízíme interval při odmontování komponenty
  }, []);

  return (
    <div>
      <p>{remainingTime}</p>
    </div>
  );
};

export default CountdownTimer;
