import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import Axios from 'axios';
import { useNavigate } from 'react-router-dom'
import queryString from 'query-string'
import './App.scss'
import { getCookieValue } from './getCookieValue.js'

function GetUser() {
  const [findUser, setFindUser] = useState({ username: '', password: '', weddingId: '' });
  const [userId, setUserId] = useState('')
  const [nonexistingUser, setNonexistingUser] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [loading, setloading] = useState(false)
  const navigate = useNavigate();
  const [weddingName, setWeddingName] = useState('')
  const [isWeddingLoaded, setIsWeddingLoaded] = useState(0)
  const [weddingId, setWeddingId] = useState('')
  const [isAdmin, setIsAdmin] = useState(false)

  const queryParams = queryString.parse(window.location.search)

  const apiUri = process.env.REACT_APP_APIURL + 'login'
  const apiUriGet = process.env.REACT_APP_APIURL + 'findWedding/'

  const base64Credentials = btoa(process.env.REACT_APP_APIUSERNAME + ':' + process.env.REACT_APP_APIPASSWORD);
  const authHeader = 'Basic ' + base64Credentials;

  const userConfig = {
    headers: {
      Authorization: authHeader
    }
  };


  const handleChange = (event) => {
    const { name, value } = event.target;
    setFindUser({
      ...findUser,
      [name]: value,
      weddingId: weddingId
    });
  };

  const handleSave = async (event) => {
    event.preventDefault();
    setloading(true)

    try {
      await findInDb();
      console.log(userId)

      setloading(false)


    } catch (error) {
      setloading(false)
      setNonexistingUser(true)
      error.response.status === 401 ? setErrorMessage('Zadaný uživatel neexistuje!') : error.response.status === 402 ? setErrorMessage('Heslo není správné!') : error.response.status === 403 ? setErrorMessage('Uživatel nemá vytvořeno heslo!') : setErrorMessage('')
      console.log(error.response.status);
    }
  };

  useEffect(() => {

    if (userId !== '') {

      navigate(`/?weddingId=${weddingId}&userId=${userId}`)

    }
  }, [userId])

  useEffect(() => {

    if (isAdmin) {

      document.cookie = "isAdmin=true"
      console.log("isAdmin")

    }
  }, [isAdmin])

  const findInDb = async () => {

    try {
      const response = await Axios.post(apiUri, findUser, userConfig);
      setUserId(response.data?.data?._id)
      setIsAdmin(response.data?.data?.admin)
      console.log("Login data", response);

      const expirationDate = new Date();
      expirationDate.setTime(expirationDate.getTime() + (6 * 60 * 60 * 1000)); // přidání 6 hodin (6 * 60 * 60 * 1000 ms)

      document.cookie = `loggedInWedding=true; expires=${expirationDate.toUTCString()}; path=/`;

    } catch (error) {
      console.log(error);
      throw error; // Re-throw the error to be caught in handleSave if needed
    }
  };

  useEffect(() => {
    !document.cookie.includes("weddingId") || !document.cookie.includes("weddingId=undefined") ? setWeddingId(getCookieValue("weddingId")) : setWeddingId(queryParams.id);
    document.cookie = `weddingId=${queryParams?.weddingId}`
    setFindUser({
      ...findUser,
      username: queryParams.user
    })
  }, []);

  useEffect(() => {


    if(isWeddingLoaded === 1 && !document.cookie.includes("shouldReload=false"))
    {
      console.log("shouldReload",isWeddingLoaded)

      const timeout = setTimeout(() => {
        console.log("reloaded!!")
        document.cookie = "shouldReload=false"
        setIsWeddingLoaded(0)
        window.location.reload();
        
      }, 2000);

      

      // Vrátit funkci pro zrušení timeoutu při odmontování komponenty nebo při změně závislostí
      return () => clearTimeout(timeout);
    }

    
  }, [isWeddingLoaded]);

  useEffect(() => {
    console.log("weddingName", weddingName)

    if(weddingName === '')
    {
      setIsWeddingLoaded(1) 
      console.log("weddingName2", weddingName)
    }
    
  }, [weddingName]);

  useEffect(() => {

    const findWedding = async () => {
      try {
        const response = await Axios.get(apiUriGet + weddingId, userConfig);
        setWeddingName(response.data.weddingName.name)
        console.log(response.data.weddingName.name);
      } catch (error) {
        console.log(error);
      }
    };

    if (weddingId) {
      findWedding();
    }


  }, [weddingId]);

  return (
    <div className='App'>
      <Container>
        <Row>
          <Col>
            <div className="col">
              <div className="row">
                <div className="col-12">
                  <div className="sg-panel sg-panel--paper">
                    <Form>
                      <Form.Group>
                        <Form.Label>
                          <b>Název svatby</b>
                        </Form.Label>
                        <br />
                        <Form.Control
                          type="text"
                          className="form_enter"
                          name="weddingName"
                          value={weddingName}
                          disabled // Disable until weddingName is available
                        />
                        <Form.Label>
                          <b>Uživatel</b>
                        </Form.Label>
                        <br />
                        <Form.Control
                          type="text"
                          className="form_enter"
                          name="username"
                          value={findUser.username}
                          onChange={handleChange}
                        />
                        <br />
                        <Form.Label>
                          <b>Heslo</b>
                        </Form.Label>
                        <br />
                        <Form.Control
                          type="password"
                          className="form_enter"
                          name="password"
                          value={findUser.password}
                          onChange={handleChange}
                        />
                      </Form.Group>
                      <Button variant="btn btn-success" type="submit" onClick={handleSave}>
                        Login
                      </Button>
                      <br />
                      <br />
                      <button className="link" onClick={() => { navigate('/register/?id=' + weddingId + '&user=' + findUser?.username) }}>
                        Změnit heslo
                      </button>
                    </Form>
                    <br />
                    <br />
                    {loading ? <div className="spinner-border text-warning" role="status"></div> : null}
                    {nonexistingUser ? <div className="alert alert-danger" role="alert"><h4 className="alert-heading">{errorMessage}</h4></div> : null}
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default GetUser;
