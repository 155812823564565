import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import Axios from 'axios';
import HeaderPart from './HeaderPart'
import HeaderMenu from './HeaderMenu'
import { getCookieValue } from './getCookieValue.js'
import './popUp.scss'
import PopUp from './PopUp'
//import QRCode from "react-qr-code";

const EnterForm = () => {
  const apiUri = process.env.REACT_APP_APIURL+'api/products/'
  const apiUriGet = process.env.REACT_APP_APIURL+'guests/'
  const url = "https://jakubasimcamajisvatbu.cz/login?id="
  // Stav pro uchování počtu skupin
  const [membersNumber, setMembersNumber] = useState(0)
  // Stav pro uchování názvů skupin
  const [groupNames, setGroupNames] = useState([])

  const [weddingName, setWeddingName] = useState('')

  const [weddingId, setWeddingId] = useState('')

  const [guests, setGuests] = useState([])

  const [successSave, setSuccessSave] = useState(false)


  const base64Credentials = btoa(process.env.REACT_APP_APIUSERNAME+':'+process.env.REACT_APP_APIPASSWORD);
  const authHeader = 'Basic ' + base64Credentials;

  const userConfig = {
  headers: {
      Authorization: authHeader
  }
  };

  // Obsluha události po kliknutí na tlačítko pro vytvoření skupin
  const handleMembersNumberClick = (event) => {
    event.preventDefault();
    // Vytvoř pole názvů skupin
    setGroupNames(Array.from({ length: membersNumber }, (_, index) => `Název skupiny ${index + 1}`));
  };

  const saveToDb = (data) => {
    Axios.post(apiUri+(weddingId !== '' ? weddingId : '111fff111fff111fff111fff'), data, userConfig)
      .then(function (response) {

        setSuccessSave(true)
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {

    if(getCookieValue("weddingId") !== null || undefined){

      setWeddingId(getCookieValue("weddingId"))

    }

  }, []) 

  useEffect(() => {

    if(weddingId !== ''){

      Axios.get(apiUriGet+weddingId, userConfig)
      .then(function (response) {

        console.log("guests",response.data.confData[0].data)
        setGuests(response.data.confData[0].data)

      })
      .catch(function (error) {
        console.log(error);
      });

    }

  }, [weddingId]) 
  

  // Obsluha události po kliknutí na tlačítko Uložit
  const handleSave = (event) => {
    event.preventDefault();
    // Vytvoř pole objektů s názvy skupin a odpovídajícími hodnotami z textových polí
    const formData = {
      name: weddingName,
      data: groupNames.map((_, index) => ({
        value: document.getElementById(`groupName_${index}`).value,
      })),
    };
  
    console.log('Uloženo:', formData);
    saveToDb(formData);

  };
  

  return (
    <div className='App'>
  <HeaderPart/>
    <Container>
      <HeaderMenu/>
        <div className="col">
          <div className="row">
            <div className="col-12">
              <div className="sg-panel sg-panel--paper">
                <Row>
                  <Col>
                    <Form>
                    <Form.Group>
                        <Form.Label><b>Název svatby</b></Form.Label>
                        <br />
                        <Form.Control
                          type="text"
                          className="text-area"
                          onChange={(e) => setWeddingName(e.target.value)}
                        />
                      </Form.Group>
                      <Form.Group>
                        <Form.Label><b>ID svatby</b></Form.Label>
                        <br />
                        <Form.Control
                          type="text"
                          className="text-area"
                          value={weddingId}
                          onChange={(e) => setWeddingId(e.target.value)}
                        />
                      </Form.Group>
                      <Form.Group>
                        <Form.Label><b>Počet skupin</b></Form.Label>
                        <br />
                        <Form.Control
                          type="number"
                          className="text-area"
                          value={membersNumber}
                          onChange={(e) => setMembersNumber(e.target.value)}
                        />
                      </Form.Group>
                      <Button className="button" variant="btn btn-success" onClick={handleMembersNumberClick}>
                        Vytvoř počet skupin
                      </Button>
                    </Form>
                    <Form>
                      <br />
                      {groupNames.map((groupName, index) => (
                        <Form.Group key={index}>
                          <br />
                          <Form.Label><b>{groupName}</b></Form.Label>
                          <Form.Control
                            id={`groupName_${index}`}
                            className="text-area"
                            type="text"
                          />
                        </Form.Group>
                      ))}
                      <Button className="button" variant="btn btn-success" onClick={handleSave}>
                        Uložit
                      </Button>
                        <br />  
                      {successSave && <PopUp message="Data byla úspěšně uložena!" />}
                    </Form>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="row">
            <div className="col-12">
              <div className="sg-panel sg-panel--paper">
                <Row>
                  <Col>
                  <h1>Vytvořené účty</h1>
                      <ul>
                        {guests.map(guest => {
                          return(
                            <li>
                              <br/>
                              <b>{guest.value}</b> - <a href={url+weddingId+'&user='+guest.value}>{url+weddingId+'&user='+guest.value}</a>
                            </li>
                          )
                        })}
                      </ul>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};


export default EnterForm;
