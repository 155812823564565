import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import './App.scss';
import './home.scss';
import { useNavigate } from 'react-router-dom';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { getCookieValue } from './getCookieValue.js';

import HeaderPart from './HeaderPart';
import HeaderMenu from './HeaderMenu'

function InfoPage() {
  const [weddingId, setWeddingId] = useState('');
  const [savedArticles, setSavedArticles] = useState([]);
  const apiUri = process.env.REACT_APP_APIURL+'articles/';
  const isLoggedIn = document.cookie.includes('loggedInWedding=true');
  const navigate = useNavigate();

  const base64Credentials = btoa(process.env.REACT_APP_APIUSERNAME+':'+process.env.REACT_APP_APIPASSWORD);
  const authHeader = 'Basic ' + base64Credentials;

  const userConfig = {
    headers: {
      Authorization: authHeader
    }
  };

  // Načtení weddingId při prvním renderu
  useEffect(() => {
    setWeddingId(getCookieValue('weddingId'));
  }, []);

  // Získání artiklů přes API při změně weddingId
  useEffect(() => {
    if (weddingId !== '') {
      getArticles();
    }
  }, [weddingId]);

  // API funkce pro získání článků
  const getArticles = () => {
    Axios.get(apiUri + weddingId, userConfig)
      .then(function (response) {
        console.log('Články načteny', response);
        // Obrátí pořadí článků
        const reversedArticles = response?.data?.articles.reverse();
        setSavedArticles(reversedArticles);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  if (isLoggedIn) {
    return (
      <div className="App">
        <HeaderPart />
        <Container>
        <HeaderMenu/>
          {savedArticles.length > 0 ? savedArticles.map(article => (
            <Row key={article._id}>
              <Col>
                <div className="col">
                  <div className="row">
                    <div className="col-12">
                      <div className="sg-panel-nav sg-panel--paper list" dangerouslySetInnerHTML={{ __html: article?.content }} />
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          )) : ''}
        </Container>
      </div>
    );
  } else {
    navigate('/login');
    return null;
  }
}

export default InfoPage;
