import React, { useState, useEffect } from 'react';
import './App.scss';
import './home.scss';
import './calendar.scss';
import { useNavigate } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import { getCookieValue } from './getCookieValue.js'
import Axios from 'axios'

import HeaderPart from './HeaderPart';
import HeaderMenu from './HeaderMenu';
import Modal from './Modal';

const questions = [
  {
    question: "Datum, kdy jsme se poznali?",
    options: ["1.1.2020", "15.3.2019", "20.7.2024"],
    correctAnswer: "15.3.2019"
  },
  {
    question: "Kde si včera bol??",
    options: ["Tady", "Tam"],
    correctAnswer: "Tady"
  },
  {
    question: "Jsi cigán??",
    options: ["Ano", "Ne"],
    correctAnswer: "Ano"
  },
  // Přidat další otázky podle potřeby
];

function PlayGround() {
  const navigate = useNavigate();
  const isLoggedIn = document.cookie.includes("loggedInWedding=true");
  const [currentDay, setCurrentDay] = useState(0);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(null);
  const [selectedAnswer, setSelectedAnswer] = useState('');
  const [answers, setAnswers] = useState({});
  const [correct, setCorrect] = useState(false);
  const [doorIndex, setDoorIndex] = useState(0)
  const [loadedAnswers, setLoadedAnswers] = useState([])
  const [question, setQuestion] = useState('')

  const apiUri = process.env.REACT_APP_APIURL+'saveanswer'
  const apiUriGet = process.env.REACT_APP_APIURL+'getanswers/'

  const base64Credentials = btoa(process.env.REACT_APP_APIUSERNAME+':'+process.env.REACT_APP_APIPASSWORD);
  const authHeader = 'Basic ' + base64Credentials;

  const userConfig = {
    headers: {
      Authorization: authHeader
    }
  };

  useEffect(() => {
    const currentDate = new Date();
    const targetDate = new Date(2024, 6, 20); // Měsíce jsou 0-indexované (7 je srpen)
    const differenceInDays = Math.floor((currentDate - targetDate) / (1000 * 60 * 60 * 24));
    setCurrentDay(differenceInDays);
    loadAnswers()


  }, []);

  const loadAnswers = () => {

    Axios.get(apiUriGet+getCookieValue("userId"), userConfig)
    .then(function (response) {
        
      setLoadedAnswers(response?.data?.data)

    })
    .catch(function (error) {
      console.log(error);
    })

  }

  useEffect(() => {

    console.log("selectedAnswer",selectedAnswer)
    
  }, [selectedAnswer]);

  const handleClick = (doorNumber) => {

    setCurrentQuestionIndex(doorNumber - 1); // Index otázky odpovídá číslu dveří minus 1
  };

  const handleAnswerSubmit = () => {
    setAnswers({ ...answers, [currentQuestionIndex]: selectedAnswer });
    setCurrentQuestionIndex(null);
    saveAnswer()
    setSelectedAnswer('');
  };

  const handleOptionChange = (selected, correct, doorIndex, question) => {

    setSelectedAnswer(selected)
    setDoorIndex(doorIndex)
    setQuestion(question)

    selected === correct ? setCorrect(true) : setCorrect(false)


  }

  const saveAnswer = () => {

    const dataToSave = {
      userId: getCookieValue("userId"),
      answer: selectedAnswer,
      correct: correct,
      doorIndex: doorIndex,
      question: question
    }

    Axios.post(apiUri, dataToSave, userConfig)
      .then(function (response) {
          
        console.log("data z API",response?.data?.resultId)

      })
      .then(function () {
        loadAnswers()
      })
      .catch(function (error) {
        console.log(error);
      })
  }

  if (!isLoggedIn) {
    navigate('/login');
    return null;
  }

  return (
    <div className='App'>
      <Modal show={currentQuestionIndex !== null}>
        {currentQuestionIndex !== null && (
          <div className="question-form">
            <h3>{questions[currentQuestionIndex]?.question}</h3>
            {questions[currentQuestionIndex]?.options.map((option, index) => (
              <div key={index}>
                <input
                  type="radio"
                  id={`option-${index}`}
                  name="answer"
                  value={option}
                  checked={selectedAnswer === option}
                  onChange={(e) => handleOptionChange(e.target.value, questions[currentQuestionIndex].correctAnswer, currentQuestionIndex, questions[currentQuestionIndex]?.question)}
                />
                <label htmlFor={`option-${index}`}>{option}</label>
              </div>
            ))}
            <button onClick={handleAnswerSubmit} disabled={!selectedAnswer}>Odeslat odpověď</button>
          </div>
        )}
      </Modal>
      <HeaderPart />
      <Container>
        <HeaderMenu />
        <div className="calendar">
          {loadedAnswers.map((door) => (
            <div key={door.doorIndex} className={door.correct ? "door open true" : "door open false" } >
              <div className="content"> {door.question}<br/>{door.answer}</div>
            </div>
          ))}
          {Array.from({ length: 24 - loadedAnswers.length }, (_, i) => i + loadedAnswers.length + 1).map((door) => (
            <div
              key={door}
              className={`door ${currentDay >= door ? 'open' : ''}`}
              onClick={() => handleClick(door)}
            >
              <div className="cover">{door}</div>
            </div>
          ))}
        </div>
      </Container>
    </div>
  );
}

export default PlayGround;
